export const defaultEditorContent = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Introducing AI Research Assistant" }],
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Welcome to our revolutionary platform for automating computer science research using advanced AI technologies. Our system leverages text-to-action LLM communication and agentic workflows to streamline the research process." },
      ],
    },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [{ type: "text", text: "Key Features" }],
    },
    {
      type: "orderedList",
      attrs: { tight: true, start: 1 },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Intuitive Web Interface" }],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "AI-Powered Literature Review" }],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Automated Experiment Design" }],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Local and Cloud Experiment Execution" }],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: "Intelligent Result Interpretation and Visualization" }],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph", // next will be smth about paper writing and paper review
              content: [{ type: "text", text: "Paper Writing and Review" }],
            },
          ],
        }
      ],
    },
    // {
    //   type: "heading",
    //   attrs: { level: 3 },
    //   content: [{ type: "text", text: "How It Works" }],
    // },
    // {
    //   type: "paragraph",
    //   content: [
    //     { type: "text", text: "Our platform facilitates a professor-student-like interaction between researchers and AI agents. Simply communicate your research ideas, and our system will guide you through the entire process:" },
    //   ],
    // },
    // {
    //   type: "orderedList",
    //   attrs: { tight: true, start: 1 },
    //   content: [
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [{ type: "text", text: "Input your research concept" }],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [{ type: "text", text: "AI performs a comprehensive literature review" }],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [{ type: "text", text: "System designs relevant experiments" }],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [{ type: "text", text: "Execute experiments locally or in the cloud" }],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [{ type: "text", text: "Receive AI-generated interpretations and visualizations" }],
    //         },
    //       ],
    //     },
    //   ],
    // },
//     {
//       type: "heading",
//       attrs: { level: 2 },
//       content: [{ type: "text", text: "Example: Optimizing MNIST Classification" }],
//     },
//     {
//       type: "paragraph",
//       content: [
//         { type: "text", text: "Let's walk through an example of using our platform to research the best optimizer for MNIST classification." },
//       ],
//     },
//     {
//       type: "heading",
//       attrs: { level: 3 },
//       content: [{ type: "text", text: "User-Agent Conversation" }],
//     },
//     {
//       type: "blockquote",
//       content: [
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "User: I want to find the best optimizer for MNIST classification.", marks: [{ type: "bold" }] },
//           ],
//         },
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "Agent: Certainly! I'll design an experiment to compare different optimizers for MNIST classification. We'll use a simple CNN and test SGD, Adam, and RMSprop optimizers. Here's the proposed experiment design:", marks: [{ type: "italic" }] },
//           ],
//         },
//         {
//           type: "orderedList",
//           attrs: { tight: true, start: 1 },
//           content: [
//             {
//               type: "listItem",
//               content: [
//                 {
//                   type: "paragraph",
//                   content: [{ type: "text", text: "Implement a basic CNN for MNIST" }],
//                 },
//               ],
//             },
//             {
//               type: "listItem",
//               content: [
//                 {
//                   type: "paragraph",
//                   content: [{ type: "text", text: "Train the model with each optimizer for 10 epochs" }],
//                 },
//               ],
//             },
//             {
//               type: "listItem",
//               content: [
//                 {
//                   type: "paragraph",
//                   content: [{ type: "text", text: "Compare final accuracy and training time" }],
//                 },
//               ],
//             },
//           ],
//         },
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "User: Sounds good. Can you show me the code for the CNN?", marks: [{ type: "bold" }] },
//           ],
//         },
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "Agent: Of course! Here's a simple CNN implementation using PyTorch:", marks: [{ type: "italic" }] },
//           ],
//         },
//       ],
//     },
//     {
//       type: "codeBlock",
//       attrs: { language: "python" },
//       content: [
//         {
//           type: "text",
//           text: `import torch
// import torch.nn as nn
// import torch.nn.functional as F

// class SimpleCNN(nn.Module):
//     def __init__(self):
//         super(SimpleCNN, self).__init__()
//         self.conv1 = nn.Conv2d(1, 32, 3, 1)
//         self.conv2 = nn.Conv2d(32, 64, 3, 1)
//         self.dropout1 = nn.Dropout2d(0.25)
//         self.dropout2 = nn.Dropout2d(0.5)
//         self.fc1 = nn.Linear(9216, 128)
//         self.fc2 = nn.Linear(128, 10)

//     def forward(self, x):
//         x = self.conv1(x)
//         x = F.relu(x)
//         x = self.conv2(x)
//         x = F.relu(x)
//         x = F.max_pool2d(x, 2)
//         x = self.dropout1(x)
//         x = torch.flatten(x, 1)
//         x = self.fc1(x)
//         x = F.relu(x)
//         x = self.dropout2(x)
//         x = self.fc2(x)
//         output = F.log_softmax(x, dim=1)
//         return output`,
//         },
//       ],
//     },
//     {
//       type: "blockquote",
//       content: [
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "User: Great! Can you run the experiment and show me the results?", marks: [{ type: "bold" }] },
//           ],
//         },
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "Agent: Certainly! I've run the experiment with the three optimizers. Here are the results:", marks: [{ type: "italic" }] },
//           ],
//         },
//       ],
//     },
//     {
//       type: "paragraph",
//       content: [{ type: "text", text: "Accuracy Comparison:" }],
//     },
//     {
//       type: "image",
//       attrs: {
//         src: "https://via.placeholder.com/600x400.png?text=Accuracy+Comparison+Graph",
//         alt: "Accuracy Comparison Graph",
//         title: "Accuracy Comparison",
//       },
//     },
//     {
//       type: "paragraph",
//       content: [{ type: "text", text: "Training Time Comparison:" }],
//     },
//     {
//       type: "image",
//       attrs: {
//         src: "https://via.placeholder.com/600x400.png?text=Training+Time+Comparison+Graph",
//         alt: "Training Time Comparison Graph",
//         title: "Training Time Comparison",
//       },
//     },
//     {
//       type: "blockquote",
//       content: [
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "Agent: Based on these results, we can conclude that Adam optimizer performs best for this MNIST classification task, achieving the highest accuracy in the shortest training time.", marks: [{ type: "italic" }] },
//           ],
//         },
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "User: Interesting! Can you provide a summary of the findings?", marks: [{ type: "bold" }] },
//           ],
//         },
//         {
//           type: "paragraph",
//           content: [
//             { type: "text", text: "Agent: Certainly! Here's a summary of our findings:", marks: [{ type: "italic" }] },
//           ],
//         },
//       ],
//     },
  
//     {
//       type: "paragraph",
//       content: [
//         { type: "text", text: "This example demonstrates how our AI-powered research assistant can help you quickly design, implement, and analyze experiments to answer your research questions." },
//       ],
//     },
  ],
};
